import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../components/about';
import Posts from '../components/posts';
import Contact from '../components/contact-modal';

const IndexPage = () => {
	return (
		<Layout>
			<SEO title="Full Stack Developer" />
			<About />
			<Posts />
			<Contact />
		</Layout>
	);
}

export default IndexPage;

import React from 'react';

const About = () => {
	const skills = [
		'JavaScript (ES6)',
		'TypeScript',
		'React',
		'Vue',
		'Node.js',
		'Express',
		'PHP',
		'WordPress',
		'Drupal',
		'Laravel',
		'GraphQL',
		'SCSS'
	];

	return (
		<div id="about" className="container mx-auto py-6 px-6">
			<div className="max-w-2xl mx-auto">
				<h2 className="text-neon-pink text-4xl font-semibold mb-2">About Me</h2>
				<div className="text-dark-blue text-lg">
					<p className="mb-6">I’m a web developer based in Washington D.C. at <a href="https://reaktivstudios.com/">Reaktiv Studios</a> where I build user-friendly and accessible websites. I have experience developing products from the ground up into functional websites and applications.</p>
					<p className="mb-6">I love to code because there is so much to learn and there is no end. I consider life my classroom and am always looking for something new to learn. I love solving complex problems, as there are always new challenges to solve. For me, it’s all in the challenge. I learn and grow more when I solve a new and difficult problem.</p>
					<p className="mb-6">When I’m not coding, you can find me playing tennis, practicing trapeze, reading comics, traveling, or watching cult classic movies.</p>
				</div>
				<div className="">
					<ul className="flex flex-row flex-wrap">
						{ skills.map(skill => (
							<li key={skill} className="skill">{skill}</li>
						)) }
					</ul>
				</div>
			</div>
		</div>
	)
};

export default About;

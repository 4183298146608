import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import ModalContext from '../context/modal';

const Contact = () => {
	const { open, toggle } = useContext(ModalContext);
	const states = {
		hidden: { opacity: 0, y: 300},
		visible: { opacity: 1, y: 0},
	};
	return (
		<AnimatePresence>
			{ open && (
				<motion.div
					key="modal"
					variants={ states }
					initial="hidden"
					animate="visible"
					exit="hidden"
					transition={{ type: 'spring', duration: 1, velocity: 1 }}
					className="bg-white fixed z-50 shadow-2xl p-6 pb-10 md:pb-6 text-center contact-box"
				>
					<div className="relative">
						<button className="absolute top-0 right-0" onClick={() => toggle(!open)}>
							<i className="lni lni-close text-neon-pink text-xl"></i>
						</button>
						<h3 className="text-neon-pink text-3xl font-semibold pt-2 mb-2">Contact Me</h3>
						<p className="text-dark-blue text-base mt-4 mb-6 md:mb-10">I'm not currently looking for freelance opportunities, but please feel free email me. I'm always open to talking and will do my best to answer your email as soon as I can.</p>
						<a className="button" href="mailto:solomon.s.scott@gmail.com">Email Me</a>
					</div>
				</motion.div>
			) }
		</AnimatePresence>
	);
}

export default Contact;

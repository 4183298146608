import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';

const Posts = () => {
	const data = useStaticQuery(graphql`
		query projects {
			allProjectsJson {
				edges {
					node {
						title
						description
						icon
						id
						link
						image
						alt
						tags
					}
				}
			}
		}
	`)

	return (
		<div id="posts" className="container mx-auto py-6 px-6 mb-10 md:mb-20">
			<h2 className="text-center text-neon-pink text-4xl font-semibold mb-6">Posts &amp; Projects</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				{data.allProjectsJson.edges.map( project => {
					return (
						<motion.div whileHover={{ y: -15 }} className="border-2 border-neon-pink relative" key={ project.node.id }>
							<div className="absolute top-0 right-0 p-4">
								<a className="text-2xl text-dark-blue" href={ project.node.link } target="_blank" rel="noopener noreferrer">
									<i className="lni lni-share"></i>
									<span className="sr-only">See Project</span>
								</a>
							</div>
							<div className="flex flex-col justify-between items-start h-full">
								{ project.node.image !== "" &&
									<div className="w-full">
										<img className="w-full" src={ `${project.node.image}` } alt={ `${project.node.alt}` } title={ `${project.node.alt}` }/>
									</div>
								}
								<div className="p-6">
									<div className="text-5xl text-dark-blue">
										<i className={ `lni lni-${ project.node.icon }` }></i>
									</div>
									<h3 className="text-dark-blue font-semibold text-xl"><a href={ project.node.link } target="_blank" rel="noopener noreferrer">{ project.node.title }</a></h3>
									<p className="text-dark-blue leading-tight text-base mt-4">{ project.node.description }</p>
								</div>
								<ul className="flex flex-row p-6">
									{project.node.tags.map( tag => (
										<li key={ tag } className="text-gray-700 font-mono text-base mr-3">{ tag }</li>
									) )}
								</ul>
							</div>
						</motion.div>
					)
				})}
			</div>
		</div>
	);
}

export default Posts;
